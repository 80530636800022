// src/contexts/ErrorHandlerContext.tsx
import React, { createContext, useContext, useCallback } from "react";
// src/util/errorHandler.ts
import axios from "axios";
import { toast } from "react-toastify";

function coreErrorHandler(error, defaultMessage) {
  const defaultMsg = defaultMessage || "Something went wrong";

  // Always log the error details
  console.error(`${defaultMsg}:`, error);

  if (axios.isAxiosError(error)) {
    const axiosError = error;

    // // Handle 403 Subscription Invalid
    // if (
    //   axiosError.response?.status === 403 &&
    //   axiosError.response.data &&
    //   axiosError.response.data.unauthorized &&
    //   setSubscriptionResponse
    // ) {
    //   //Not subscribed
    //   setSubscriptionResponse(axiosError.response.data);
    //   return;
    // }

    // Try to get server message
    const serverMessage =
      axiosError.response?.data?.error || axiosError.response?.data?.message;

    toast.error(serverMessage || defaultMsg);
  } else if (error instanceof Error) {
    // Handle custom errors with JSON data
    const customError = error;

    toast.error(customError.data?.message || customError.message || defaultMsg);

    // if (
    //   customError.data?.code == "EXISTING_SUBSCRIPTION"
    // ) {
    //   // User already has a subscription, redirect them to loading:
    //   setSubscriptionResponse(null);
    // }

    // // Handle custom status codes if needed
    // if (customError.statusCode === 403 && setSubscriptionResponse) {
    //   setSubscriptionResponse(customError.data);
    // }
  } else {
    // Fallback for non-error objects
    toast.error(defaultMsg);
  }
}

const ErrorHandlerContext = createContext({
  handleApiError: () => {},
});

export const ErrorHandlerProvider = ({ children }) => {
  const handleApiError = useCallback((error, defaultMessage) => {
    coreErrorHandler(error, defaultMessage);
  }, []);

  return (
    <ErrorHandlerContext.Provider value={{ handleApiError }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};

export const useErrorHandler = () => useContext(ErrorHandlerContext);
