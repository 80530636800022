import React, { useContext, useEffect, useState } from "react";
import { AppFeatures } from "../components/AppFeatures";
import { PageLayout } from "../components/page-layout";
import { Heading, Subheading } from "../components/catalyst/heading";
import { Divider } from "../components/catalyst/divider";
import { useAuth0 } from "@auth0/auth0-react";
import AppDownload from "../components/AppDownload";
import AppContext from "../context/AppContext";

export const HomePage = () => {
  const { user } = useAuth0();
  const { isLoadingData, userData } = useContext(AppContext);
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();
    if (hour < 10) {
      setGreeting("Good morning");
    } else if (hour >= 10 && hour < 15) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);
  return (
    <PageLayout>
      <Heading className="text-4xl mb-4">
        {greeting}
        {user && user.name && `, ${user.name}`}
      </Heading>
      {!isLoadingData &&
        userData &&
        userData.Employments &&
        userData.Employments.length && (
          <Subheading className="mb-4">
            {userData.Employments[0].name}
          </Subheading>
        )}

      <AppFeatures />

      <AppDownload />
    </PageLayout>
  );
};
