import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { universalAxios } from "../lib/functions";
import { useErrorHandler } from "../context/ErrorHandlerContext";
import { XMarkIcon } from "@heroicons/react/16/solid";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PriceCard(props) {
  const { item } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [showCouponField, setShowCouponField] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState({
    amount_off: null,
    percent_off: null,
  });
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [couponError, setCouponError] = useState("");

  const { getAccessTokenSilently } = useAuth0();
  const { handleApiError } = useErrorHandler();

  const createCheckoutSession = async () => {
    if (isValidating) return;

    if (!isValidCoupon && couponCode) {
      setCouponError("Please validate your coupon first");
      return;
    }

    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      if (!accessToken) return;

      const response = await universalAxios({
        url: "/api/user/create-checkout-session",
        method: "POST",
        token: accessToken,
        requestData: {
          planId: item.id,
          couponCode: isValidCoupon ? couponCode : null,
          successUrl: `${window.location.origin}/payment-success?checkout_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${window.location.origin}/subscription`,
        },
      });
      if (response.success && response.sessionUrl) {
        // Redirect to Stripe Checkout
        window.location.href = response.sessionUrl;
      } else {
        toast.error(response.message || "Could not create checkout session");
      }
    } catch (error) {
      handleApiError(error, "Checkout session could not be created");
    } finally {
      setIsLoading(false);
    }
  };

  function getColor(interval, textColor) {
    if (textColor) {
      switch (interval) {
        case "Yearly":
          return "#fdf8ff";
        case "Monthly":
          return "#ffffff";
        default:
          return "#ffffff";
      }
    }
    switch (interval) {
      case "Yearly":
        return "#9f0ff2";
      case "Monthly":
        return "#f78f1e";
      default:
        return "red";
    }
  }

  const validateCoupon = async () => {
    if (!couponCode.trim()) {
      setCouponError("Please enter a coupon code");
      return;
    }

    try {
      setIsValidating(true);
      const accessToken = await getAccessTokenSilently();
      const response = await universalAxios({
        url: `/app/api/users/coupon-validate?code=${couponCode}&priceId=${item.stripePriceId}`,
        method: "GET",
        token: accessToken,
      });

      if (response.valid) {
        setIsValidCoupon(true);
        setCouponError("");
        setCouponData(response.couponData);
        toast.success(response.message || "Coupon Applied!");
      } else {
        setIsValidCoupon(false);
        setCouponData(null);
        setCouponError(response.message || "Invalid coupon code");
      }
    } catch (error) {
      setIsValidCoupon(false);
      setCouponData(null);
      setCouponError("Error validating coupon");
    } finally {
      setIsValidating(false);
    }
  };

  function renderFinalPrice() {
    if (isValidCoupon && couponData) {
      if (couponData.amount_off)
        return Math.max((item.amount - couponData.amount_off) / 100, 0).toFixed(
          2
        );
      if (couponData.percent_off)
        return (
          (item.amount * (100 - couponData.percent_off)) /
          100 /
          100
        ).toFixed(2);
    }
    return (item.amount / 100).toFixed(2);
  }

  return (
    <div
      className={classNames(
        item.interval === "Yearly"
          ? "relative bg-gray-900 shadow-2xl sm:py-[50px!important]"
          : "bg-gray-800 lg:mx-0",
        item.interval === "Yearly"
          ? ""
          : "rounded-t-3xl rounded-br-3xl sm:rounded-br-none rounded-b-none sm:rounded-tr-none rounded-bl-3xl",
        "rounded-3xl p-4 ring-1 ring-gray-900/10 sm:p-6 flex-1"
      )}
    >
      <div
        className=" rounded-2xl overflow-hidden h-full p-2 px-2"
        style={{ borderColor: getColor(item.interval) }}
      >
        <div className="flex justify-between mb-10">
          <span className="text-white text-base">Payeee Subscription</span>

          {item.interval === "Yearly" && (
            <span
              className="px-2.5 py-0.5 rounded-full text-xs items-center flex"
              style={{
                backgroundColor: getColor(item.interval),
                color: getColor(item.interval, true),
              }}
            >
              Most Popular
            </span>
          )}
        </div>
        <div className="mb-5">
          <h2
            style={{
              color: getColor(item.interval),
            }}
            className={classNames(" font-semibold")}
          >
            {item.name}
          </h2>
        </div>
        <div className="flex items-center justify-center gap-2.5 mb-5">
          {(item.oldAmount || (isValidCoupon && couponData)) && (
            <span className="line-through text-[#aeaeae] text-lg">
              {item.currency == "gbp" ? "£" : "$"}
              {isValidCoupon && couponData
                ? (item.amount / 100).toFixed(2)
                : (item.oldAmount / 100).toFixed(2)}
            </span>
          )}

          <span
            className="text-3xl font-bold"
            style={{ color: getColor(item.interval) }}
          >
            {item.currency == "gbp" ? "£" : "$"}
            {renderFinalPrice()}
          </span>
          <span className="text-[#aeaeae] text-base">
            / {item.interval == "Yearly" ? "Year" : "Month"}
          </span>
        </div>

        <p
          className={classNames(
            item.interval === "Yearly" ? "text-gray-300" : "text-gray-300",
            "mt-6 text-base/7 text-left"
          )}
        >
          {item.description}
        </p>

        <div className="my-2.5 space-y-2.5">
          {!isValidCoupon && (
            <>
              <button
                onClick={() => setShowCouponField(!showCouponField)}
                className="flex items-center gap-1.5"
              >
                <span className="text-[#aeaeae]">
                  {showCouponField ? "↑" : "↓"}
                </span>
                <span className="text-[#aeaeae]">Have a coupon code?</span>
              </button>

              {showCouponField && (
                <div className="space-y-2 text-left">
                  <input
                    type="text"
                    placeholder="Enter coupon code"
                    className="bg-[#1a1a1a] text-white p-3 rounded border w-full"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        validateCoupon();
                      }
                    }}
                    style={{ borderColor: couponError ? "#ff4444" : "#333" }}
                    value={couponCode}
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                      setCouponError("");
                    }}
                  />

                  {couponError && (
                    <p className="text-[#ff4444] text-xs">{couponError}</p>
                  )}

                  <button
                    onClick={validateCoupon}
                    className="bg-[#111] p-2.5 rounded text-center w-1/2 hover:opacity-70 transition"
                  >
                    {isValidating ? (
                      <span
                        className="inline-block h-5 w-5 border-2 border-transparent border-t-current rounded-full animate-spin"
                        style={{
                          borderTopColor: getColor(item.interval, true),
                        }}
                      />
                    ) : (
                      <span className="text-white">Apply Coupon</span>
                    )}
                  </button>
                </div>
              )}
            </>
          )}

          {isValidCoupon && (
            <div
              className="flex items-center bg-[#333] rounded-full pl-4 pr-1 py-2 self-start border transition border-transparent justify-between w-fit relative cursor-pointer hover:border-gray-400"
              onClick={() => {
                setCouponCode("");
                setIsValidCoupon(false);
                setCouponData(null);
                setCouponError("");
                setShowCouponField(false);
              }}
            >
              <span className="text-white mr-2">{couponCode}</span>
              <button>
                <XMarkIcon className="w-4 text-white" />
              </button>
            </div>
          )}
        </div>
        <button
          disabled={isLoading}
          onClick={createCheckoutSession}
          className="w-full p-2.5 rounded text-center my-2.5 transition hover:opacity-75"
          style={{
            backgroundColor: getColor(item.interval),
            color: getColor(item.interval, true),
          }}
        >
          {isLoading ? (
            <span
              className="inline-block h-5 w-5 border-2 border-transparent border-t-current rounded-full animate-spin"
              style={{ borderTopColor: getColor(item.interval, true) }}
            />
          ) : (
            "Subscribe"
          )}
        </button>

        <div className="space-y-2.5 mt-5">
          {item.features.map((feature, index) => (
            <div key={index} className="flex items-center gap-2.5 px-2.5">
              <span style={{ color: getColor(item.interval) }}>✓</span>
              <span className="text-white text-base">{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
