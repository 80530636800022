import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PaymentSection from "../components/PaymentSection";

function SubscriptionWidget() {
  const { user } = useAuth0();
  const [value, setValue] = useState("yearly");

  if (!user) return null;

  return (
    <div className="relative isolate lg:px-8">
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-base/7 font-semibold text-[#f88e06]">Pricing</h2>
        <p className="mt-2 text-5xl font-semibold tracking-tight text-balance text-white sm:text-6xl">
          Select a plan
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg font-medium text-pretty text-gray-600 sm:text-xl/8">
        Choose an affordable plan that’s packed with the best features for
        engaging your audience, creating customer loyalty, and driving sales.
      </p>

      <div className="flex-1">
        <PaymentSection plan={value} />
      </div>
    </div>
  );
}

export default SubscriptionWidget;
