import React from "react";
import { Subheading } from "./catalyst/heading";
import { Divider } from "./catalyst/divider";
import { Text } from "./catalyst/text";

import GoogleBadge from "../assets/google-play-badge.png";
import AppleBadge from "../assets/app-store-badge.png";

function AppDownload() {
  return (
    <div className="mt-8">
      <Subheading>Download Our App</Subheading>
      <Text>
        We're excited to announce that our app is available on both the Apple
        Store and Google Play Store! Experience the full range of our features
        on your mobile device. Whether you're using an iPhone or an Android, you
        can stay connected and enjoy all the benefits our service has to offer.
      </Text>
      <Divider className="mt-4 mb-8" soft />

      <div className="flex gap-8 items-center justify-center">
        <a
          href="https://play.google.com/store/apps/details?id=com.payeee.payeee"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={GoogleBadge}
            alt="Download From Google Play Store"
            width={200}
            className="w-auto h-[75px]"
          />
        </a>
        <a
          href="https://apps.apple.com/gb/app/payeee/id6648789660"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={AppleBadge}
            alt="Download From the Apple app Store"
            width={200}
            className="w-auto h-[75px]"
          />
        </a>
      </div>
    </div>
  );
}

export default AppDownload;
