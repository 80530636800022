import axios from "axios";

export async function universalAxios({
  url,
  method,
  requestData,
  params,
  token,
}) {
  const { data } = await axios({
    url: process.env.REACT_APP_API_SERVER_URL + url,
    method: method ? method : "GET",
    ...(requestData && { data: JSON.stringify(requestData) }),
    params,
    headers: {
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  });

  return data;
}
