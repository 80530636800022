import axios from "axios";

export default async function fetchBillings(token, page = 1) {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}/api/user/billings?page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
}
