import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import PriceCard from "./PriceCard";
import { universalAxios } from "../lib/functions";
import { useErrorHandler } from "../context/ErrorHandlerContext";

// Import Swiper styles and modules
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function PaymentSection() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState([]);
  const [isError, setIsError] = useState("");

  const { handleApiError } = useErrorHandler();
  useEffect(() => {
    const fetchPrices = async () => {
      try {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently();
        if (!accessToken) return;

        const response = await universalAxios({
          url: "/app/api/users/prices",
          method: "GET",
          token: accessToken,
        });

        if (!response.success && response.message) {
          toast.error(response.message);
        }

        if (response.success) {
          if (response.existingSubscription) {
            const error = new Error("Existing Subscription");
            error.data = {
              code: "EXISTING_SUBSCRIPTION",
              message: "You already have an active subscription",
            };
            setIsError("You already have an active subscription");
            // throw error;
          } else if (response.companyEmployee) {
            const error = new Error("Company Employee");
            error.data = {
              code: "COMPANY_EMPLOYEE",
              message:
                "You are not allowed to buy for a subscription as this account is part of a company",
            };
            setIsError(
              "You are not allowed to buy for a subscription as this account is part of a company"
            );
            throw error;
          } else {
            setPrices(response.data);
          }
        }
      } catch (error) {
        console.log("Something went wrong:", error);
        handleApiError(error, "Prices could not be fetched");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrices();
  }, []);

  if (!isLoading && !prices.length && !isError) {
    return (
      <div className="flex-1 mt-5">
        <h2 className="text-white text-center text-xl font-bold mb-2">Alert</h2>
        <p className="text-white text-center">
          No active pricing plans found. Please try again later.
        </p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-1 mt-5 bg-red-900/20 border border-gray-800/70 text-xs py-4 rounded-lg">
        <p className="text-center text-sm text-red-500 font-bold mb-2">Error</p>
        <p className="text-white text-center">{isError}</p>
      </div>
    );
  }

  return (
    <div className="mx-auto mt-4 flex  sm:mt-8 lg:max-w-4xl items-center justify-center py-16">
      {isLoading ? (
        <div className="col-span-2 flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
        </div>
      ) : (
        <>
          {/* Desktop View - Grid Layout */}
          <div className="hidden sm:grid items-center lg:grid-cols-2 gap-y-0  grid-cols-2">
            {prices.map((price, key) => (
              <PriceCard key={key} item={price} />
            ))}
          </div>

          {/* Mobile View - Swiper Slider */}
          <Swiper
            className="mySwiper sm:hidden"
            slidesPerView={1.4}
            centeredSlides={true}
            spaceBetween={25}
          >
            {prices.map((price, key) => (
              <SwiperSlide key={key} className="flex">
                <PriceCard item={price} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
}

export default PaymentSection;
