import { useState, useEffect } from "react";
export default function useDarkMode() {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    setIsDark(darkModeMediaQuery.matches);

    const listener = (e) => setIsDark(e.matches);
    darkModeMediaQuery.addEventListener("change", listener);

    return () => darkModeMediaQuery.removeEventListener("change", listener);
  }, []);
  return isDark;
}
