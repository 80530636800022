import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AppContext = createContext({
  userData: null,
  isLoadingData: true,
  error: null,
  refreshUserData: () => {},
});

export const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    userData: null,
    isLoadingData: true,
    error: null,
  });

  const { user, getAccessTokenSilently, logout } = useAuth0();

  const refreshUserData = useCallback(async () => {
    if (!user || !user?.sub) return;

    setState((prev) => ({ ...prev, isLoadingData: true, error: null }));

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      });

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          timeout: 10000,
        }
      );

      setState((prev) => ({
        ...prev,
        userData: {
          ...data.userData,
        },
        auth0Data: {
          ...data.auth0Data,
        },
        error: null,
      }));
    } catch (error) {
      console.error("User data fetch error:", error);

      if (error.response?.status === 401) {
        await logout({
          logoutParams: { returnTo: window.location.origin },
        });
      }

      setState((prev) => ({
        ...prev,
        error: error.response?.data?.message || "Failed to load user data",
      }));

      toast.error(error.response?.data?.message || "Failed to load user data");
    } finally {
      setState((prev) => ({ ...prev, isLoadingData: false }));
    }
  }, [user?.sub, getAccessTokenSilently, logout]);

  useEffect(() => {
    const fetchData = async () => {
      await refreshUserData();
    };
    if (user) {
      fetchData();
    } else {
      setState({ userData: null, isLoadingData: false, error: null });
    }
  }, [user, refreshUserData]);

  return (
    <AppContext.Provider value={{ ...state, refreshUserData }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
