import React, { useEffect, useState } from "react";
import { Subheading } from "../components/catalyst/heading";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/catalyst/table";
import Spinner from "../components/ui/Spinner";
import { PageLayout } from "../components/page-layout";
import Pagination from "../components/Pagination";
import { Link, useSearchParams } from "react-router-dom";
import fetchBillings from "../lib/fetchBillings";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "../components/catalyst/avatar";

function BillingHistory() {
  const [isLoading, setIsLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [billings, setBillings] = useState([]);

  const [searchParams] = useSearchParams();

  // useEffect(() => {
  //   console.log("change");
  // }, [searchParams]);

  const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
      setCurrentPage(+searchParams.get("page") || 1);
    }, [searchParams]);

  useEffect(() => {
    const fetchBillingsHistory = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const data = await fetchBillings(token, currentPage);
        setBillings(data.invoices);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error(error);
        // toast.error("Error while fetching billings");
      } finally {
        setIsLoading(false);
      }
    };
    fetchBillingsHistory();
  }, [currentPage, getAccessTokenSilently]);
  return (
    <PageLayout>
      <Subheading className="mt-14">Recent orders</Subheading>
      <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
        <TableHead>
          <TableRow>
            <TableHeader>Billing ID</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>Purchase date</TableHeader>
            <TableHeader>Amount</TableHeader>
            <TableHeader className="text-right">Invoice</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && billings.length
            ? billings.map((billing) => {
                return (
                  <TableRow
                    key={billing.invoiceId}
                    title={`Billing #${billing.id}`}
                  >
                    <TableCell>{billing.invoiceId}</TableCell>
                    <TableCell>{billing.productDescription ?? "N/A"}</TableCell>
                    <TableCell className="text-zinc-500">
                      {new Date(
                        billing.subscriptionStart * 1000
                      ).toDateString()}
                    </TableCell>
                    <TableCell>{billing.amount}</TableCell>
                    <TableCell className="text-right">
                      {" "}
                      <a
                        href={billing.invoiceURL}
                        target="_blank"
                        className="text-right text-blue-500 hover:underline"
                        rel="noreferrer"
                      >
                        View Invoice
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })
            : ""}
        </TableBody>
      </Table>
      {!isLoading && billings.length ? (
        <div className="py-4">
          <Pagination currentPage={currentPage} totalPages={totalPages} />
        </div>
      ) : (
        ""
      )}
      {isLoading ? (
        <div className="flex py-8 justify-center w-full">
          <Spinner />
        </div>
      ) : !billings.length ? (
        <div className="flex py-8 justify-center w-full">
          <Subheading>No Billings saved</Subheading>
        </div>
      ) : (
        ""
      )}
    </PageLayout>
  );
}

export default BillingHistory;
