import { Heading, Subheading } from "../components/catalyst/heading";
import { PageLayout } from "../components/page-layout";

import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "../components/catalyst/alert";
import { Button } from "../components/catalyst/button";
import { useEffect, useState } from "react";
import Spinner from "../components/ui/Spinner";
import fetchSubscriptionStatus from "../lib/fetchSubscriptionStatus";
import unsubscribeUser from "../lib/unsubscribeUser";
import { useAuth0 } from "@auth0/auth0-react";
import { Text } from "../components/catalyst/text";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SubscriptionWidget from "./SubscriptionWidget";

function Subscription() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const token = await getAccessTokenSilently();
        const status = await fetchSubscriptionStatus(token);

        setIsSubscribed(status.isSubscribed);
      } catch (err) {
        setError("Failed to fetch subscription status.");
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, [getAccessTokenSilently]);

  return (
    <PageLayout>
      <div className="relative isolate overflow-hidden">
        <div className="sm:px-6  lg:px-8">
          <div className="mx-auto text-center">
            <SubscriptionWidget />
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Subscription;
