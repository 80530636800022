import React, { useState, useContext } from "react";
import { Avatar } from "../catalyst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../catalyst/dropdown";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "../catalyst/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "../catalyst/sidebar";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ShieldCheckIcon,
} from "@heroicons/react/16/solid";

import PayeeLogoDark from "../../assets/payee-logo1.png";
import PayeeLogoWhite from "../../assets/payee-logo.jpg";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "../ui/Spinner";
import { Link, useLocation } from "react-router-dom";

import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "../catalyst/alert";
import { Button } from "../catalyst/button";
import useDarkMode from "../../hooks/useDarkMode";
import AppContext from "../../context/AppContext";

const navItems = [
  { label: "Home", url: "/" },
  { label: "Billing History", url: "/billing" },
  { label: "Subscription Management", url: "/subscription", b2cSpecific: true },
  { label: "Support", url: "/support" },
];

export const NavBar = () => {
  const { user, isLoading, logout, loginWithRedirect } = useAuth0();

  const { userData, isLoadingData } = useContext(AppContext);

  const darkMode = useDarkMode();
  let [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <Navbar className="mx-auto max-w-6xl">
      <Link to="/">
        <img src={darkMode ? PayeeLogoDark : PayeeLogoWhite} width={150} />
      </Link>
      <NavbarDivider className="max-lg:hidden" />

      <NavbarSection className="max-lg:hidden">
        {navItems.map(({ label, url, b2cSpecific }) => {
          if (
            b2cSpecific &&
            (isLoadingData ||
              (userData && userData.Employments && userData.Employments.length))
          ) {
            return <></>;
          }
          return (
            <NavbarItem
              key={label}
              href={url}
              current={location.pathname === url}
            >
              {label}
            </NavbarItem>
          );
        })}
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection>
        {isLoading ? (
          <Spinner />
        ) : !user ? (
          <NavbarItem onClick={handleLogin}>Login</NavbarItem>
        ) : (
          <>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <div>
                  {!user || isLoadingData || !userData ? (
                    <Spinner />
                  ) : (
                    <Avatar
                      src={
                        userData.avatarUrl ? userData.avatarUrl : user.picture
                      }
                      className="max-w-16 max-h-16  overflow-hidden [&_img]:object-contain"
                      square
                    />
                  )}
                </div>
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/account">
                  <ShieldCheckIcon />
                  <DropdownLabel>Account</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem onClick={() => setIsOpen(true)}>
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Alert open={isOpen} onClose={setIsOpen}>
              <AlertTitle>Are you sure you want to sign out ?</AlertTitle>
              <AlertActions>
                <Button plain onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setIsOpen(false);
                    handleLogout();
                  }}
                >
                  Sign Out
                </Button>
              </AlertActions>
            </Alert>
          </>
        )}
      </NavbarSection>
    </Navbar>
  );
};

export const MobileNavBar = () => {
  const { user } = useAuth0();

  const { userData, isLoadingData } = useContext(AppContext);
  return (
    <Sidebar>
      <SidebarHeader>
        {!user || isLoadingData || !userData ? (
          <Spinner />
        ) : (
          <Avatar
            src={userData.avatarUrl ? userData.avatarUrl : user.picture}
            square
            className="max-w-10 max-h-10 "
          />
        )}
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          {navItems.map(({ label, url, b2cSpecific }) => {
            if (
              b2cSpecific &&
              (isLoadingData ||
                (userData &&
                  userData.Employments &&
                  userData.Employments.length))
            ) {
              return <></>;
            }
            return (
              <SidebarItem key={label} href={url}>
                {label}
              </SidebarItem>
            );
          })}
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
};
